import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/illus_home.png",
          "alt": "Welcome to Comm-dev"
        }}></img></p>
      <Box mdxType="Box">
        <h2>{`Welcome to Community Development at MakerDAO.`}</h2>
        <p>{`Learn, contribute, and join the Community.`}</p>
      </Box>
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <h2>{`Level Up Your Maker Knowledge`}</h2>
        <p>{`Whether you’re new to Maker, or an expert looking for details, we have you covered. Our community-generated resources will help you level up your MakerDAO knowledge.`}</p>
        <Button to="/learn/" mdxType="Button">Start Learning</Button>
      </Box>
      <p><img parentName="p" {...{
          "src": "/images/illus_learn.png",
          "alt": "Learn"
        }}></img></p>
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/illus_funding.png",
          "alt": "Funding"
        }}></img></p>
      <Box mdxType="Box">
        <h2>{`Start Your Idea`}</h2>
        <p>{`We offer grants towards improving MakerDAO and Dai community coordination and resources. Discover how our grants program can bootstrap your idea.`}</p>
        <Button to="/funding/" mdxType="Button">Get Funding</Button>
      </Box>
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <h2>{`Play a Part`}</h2>
        <p>{`You can help us make the community even better. Check out our open bounties, create and edit content, help with translations, and more.`}</p>
        <Button to="/contribute/" mdxType="Button">Work with us</Button>
      </Box>
      <p><img parentName="p" {...{
          "src": "/images/illus_work.png",
          "alt": "Work With Us"
        }}></img></p>
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/humans-of-makerdao.png",
          "alt": "Humans"
        }}></img></p>
      <Box mdxType="Box">
        <h2>{`100% Remote`}</h2>
        <h2>{`100% Human`}</h2>
        <h2>{`Say Hello`}</h2>
        <p>{`Help us grow and make the MakerDAO community even better. Reach out in our chat to find out how you can get involved today.`}</p>
        <Button to="https://chat.makerdao.com/channel/community-development" icon="rocketchat" mdxType="Button">Join Our Chat</Button>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      